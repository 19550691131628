import { createStitches } from "@stitches/react";

export const { styled } = createStitches({
  theme: {
    colors: {
      blue: "#3a3d57",
      dblue: "#1f2242",
      green: "#14cc76",
      bgreen: "#41e592",
      gray100: "#e8ecf4",
      gray300: "#667b99",
      primary900: "#3a3d57",
      blue100: "#cad5e7",
      blue900: "#064ab5",
      red100: "#f5d9d9",
      red900: "#900",
      green100: "#d9f3e3",
      green900: "#0b662d",
      yellow100: "#fff6ca",
      yellow900: "#ffbc0f",
    },
    radii: {
      small: "4px",
    },
    shadows: {
      button: "0 3px 0 #201e303f",
      medium: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
  },
});
