import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import { AuthProvider, RequireAuth } from "./components/Auth";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { CreateContentBlock } from "./routes/CreateContentBlock";
import { CreateOffer } from "./routes/CreateOffer";
import { Login } from "./routes/Login";
import { Logout } from "./routes/Logout";
import { Recents } from "./routes/Recents";
import { Profile } from "./routes/Profile";

const root = document.getElementById("root") as HTMLElement;
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="auth">
            <Route path="login" element={<Login />} />
          </Route>
          <Route
            path="/"
            element={
              <RequireAuth>
                <App />
              </RequireAuth>
            }>
            <Route path="auth">
              <Route path="logout" element={<Logout />} />
            </Route>
            <Route path="/" element={<Recents />}></Route>
            <Route
              path="/contentblock/create"
              element={<CreateContentBlock />}></Route>
            <Route path="/offer/create" element={<CreateOffer />}></Route>
            <Route path="/profile" element={<Profile />}></Route>
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
  root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
