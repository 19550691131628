import { Outlet } from "react-router-dom";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { ToastViewport, ToastProvider } from "./components/Toast";
import { styled } from "./theme";

const BaseApp = () => {
  return (
    <ToastProvider>
      <Header />
      <Outlet />
      <Footer />
      <ToastViewport />
    </ToastProvider>
  );
};

const App = styled(BaseApp, {
  backgroundColor: "green",
});

export default App;
