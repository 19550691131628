import { FormEvent, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginAccessToken, useAuth } from "../components/Auth";
import { Button } from "../components/Button";
import { Fieldset, Form, Input, Label } from "../components/FormComponents";
import { styled } from "../theme";
import API from "../utils/API";
import HistoryState from "../utils/HistoryState";
import { transposeFormDataEntries } from "../utils/transpose";
import background from "../assets/img/twn-bg.png";
import logo from "../assets/img/twn-logo-white.svg";

const LoginMain = styled("main", {
  backgroundColor: "white",
  backgroundImage: `url(${background})`,
  backgroundSize: "cover",
  backgroundPosition: "center center",
  display: "grid",
  height: "100vh",
  alignItems: "center",
  justifyContent: "center",
});

const LoginSection = styled("section", {
  padding: "2em 8ch",
  width: "435px",
  height: "565px",
  maxWidth: "100vw",
  maxHeight: "100vh",
  background: "rgba(20, 9, 52, 0.7)",
  justifyContent: "center",
  borderRadius: "20px",
  display: "grid",
  gridTemplateRows: "150px",
  gridTemplateColumns: "300px",
  gridTemplateAreas: `
  "logo"
  "loginform"
  `,
});

const Logo = styled("div", {
  backgroundImage: `url(${logo})`,
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat",
  height: "100%",
  maxWidth: "100vw",
  gridArea: "logo",
});

const LoginButton = styled(Button, {
  backgroundColor: "$green",
  color: "$dblue",
  boxShadow: "$button",
  "&:hover": { backgroundColor: "$bgreen" },
  alignSelf: "end",
  marginTop: "20px",
});

const LoginForm = styled(Form, {
  gridArea: "loginform",
  maxWidth: "100vw",
  padding: "0",
});

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let auth = useAuth();
  const form = useRef<HTMLFormElement | null>(null);

  let from = (location.state as HistoryState | null)?.from?.pathname ?? "/";

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const formData = new FormData(form.current as HTMLFormElement);

    const data = transposeFormDataEntries(formData.entries());
    const result = await API.post("/auth/login", JSON.stringify(data));

    if (result.ok) {
      const accessToken = await result.json().then((json) => {
        const loginAccessToken = json as { access_token: string };
        return loginAccessToken.access_token;
      });
      auth.assignAccessToken(accessToken);
      document.cookie = `access_token=${accessToken}`;
      navigate(from, { replace: true });
    }
  };

  return (
    <LoginMain>
      <LoginSection>
        <Logo />
        <LoginForm ref={form} onSubmit={handleSubmit}>
          <Fieldset>
            <Label htmlFor="username" css={{ color: "white" }}>
              Email
            </Label>
            <Input id="username" name="username" />
          </Fieldset>
          <Fieldset>
            <Label htmlFor="password" css={{ color: "white" }}>
              Salasõna
            </Label>
            <Input id="password" name="password" type="password" />
          </Fieldset>
          <LoginButton>Logi sisse</LoginButton>
        </LoginForm>
      </LoginSection>
    </LoginMain>
  );
};

export { Login };
