import { FormEvent, useContext, useEffect, useRef, useState } from "react";
import { BsXCircle } from "react-icons/bs";
import { Button } from "../components/Button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "../components/Dialog";
import {
  Fieldset,
  Form,
  Input,
  Label,
  TagInput,
  TagLI,
  TagUL,
} from "../components/FormComponents";
import { ToastContext, ToastType } from "../components/Toast";
import { Direction, WideBlock } from "../components/WideBlock";
import { styled } from "../theme";
import API from "../utils/API";
import { ContentBlock } from "../utils/ContentBlock";
import downloadBlob from "../utils/downloadBlob";
import { transposeFormDataEntries } from "../utils/transpose";
import { Main } from "../components/Main";

const SplitOuterContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
});

const HeaderContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  paddingBlock: ".5rem",
  paddingInlineEnd: "1rem",
  gap: ".5rem",
  "& > h1": {
    flex: "1",
    marginBlock: "0",
  },
});

const SplitContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
});

const SplitWindow = styled("section", {
  flex: "1",
});

const WideBlockContainer = styled("div", {
  paddingInlineEnd: "1rem",
  flex: "1",
  maxHeight: "60vh",
  overflowY: "scroll",
  display: "flex",
  flexDirection: "column",
  gap: ".5rem",
});

const ButtonGroup = styled("div", {
  display: "flex",
  gap: ".5rem",
  justifyContent: "end",
});

const CreateOffer = () => {
  const [leftContentBlocks, setLeftContentBlocks] = useState<ContentBlock[]>(
    []
  );
  const [rightContentBlocks, setRightContentBlocks] = useState<ContentBlock[]>(
    []
  );
  const toast = useContext(ToastContext);

  const fetchData = async () => {
    const response = await API.get("/contentblocks");
    if (response.ok) {
      const json = await response.json();
      setLeftContentBlocks(json);
    } else {
      toast?.createToast(ToastType.ERROR, "Sisuplokkide laadimine ebaõnnestus");
      setLeftContentBlocks([]);
    }
  };

  useEffect(() => {
    fetchData().catch(console.error);
  }, []);

  const moveContentBlockToRight = (contentBlock: ContentBlock) => {
    const filteredArray = leftContentBlocks.filter(
      (block) => block._id !== contentBlock._id
    );
    setLeftContentBlocks(filteredArray);
    const blocks = [...rightContentBlocks, contentBlock];
    setRightContentBlocks(blocks);
  };

  const moveContentBlockToLeft = (contentBlock: ContentBlock) => {
    const filteredArray = rightContentBlocks.filter(
      (block) => block._id !== contentBlock._id
    );
    setRightContentBlocks(filteredArray);
    const blocks = [...leftContentBlocks, contentBlock];
    setLeftContentBlocks(blocks);
  };

  const exportToDocx = async () => {
    const ids = rightContentBlocks.map((block) => block._id);
    const blob = await API.post("/offers/export", JSON.stringify({ ids })).then(
      (res) => res.blob()
    );
    const timestamp = new Date().toISOString().replaceAll(":", ".");
    downloadBlob(`${timestamp}.docx`, blob);
  };

  const [tags, setTags] = useState<string[]>([]);

  const addTags = (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.currentTarget.value) {
      setTags([...tags, e.currentTarget.value]);
      e.currentTarget.value = "";
    }
  };

  const removeTags = (index: number) => {
    tags.splice(index, 1);
    setTags(tags);
  };

  const moveInDirection = (
    contentBlock: ContentBlock,
    direction: Direction
  ) => {
    const ids = rightContentBlocks.map((b) => b._id);
    const blocks = [...rightContentBlocks];
    const i = ids.indexOf(contentBlock._id);
    // Handle trying to move outside the array
    if (
      (i === 0 && direction === Direction.UP) ||
      (i === ids.length - 1 && direction === Direction.DOWN)
    ) {
      return;
    }
    blocks.splice(i, 1);
    blocks.splice(i + direction, 0, contentBlock);
    setRightContentBlocks(blocks);
  };

  const form = useRef<HTMLFormElement>(null);
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    const ids = rightContentBlocks.map((block) => block._id);
    const formData = new FormData(form.current as HTMLFormElement);

    const data = transposeFormDataEntries(formData.entries()) as {
      [key: string]: string | string[];
    };

    if (!data["title"]) {
      toast?.createToast(ToastType.ERROR, "Pakkumusel peab olema pealkiri");
      return;
    }
    if (ids.length === 0) {
      toast?.createToast(
        ToastType.ERROR,
        "Pakkumus peab koosnema vähemalt ühest sisuplokist"
      );
      return;
    }
    data["ids"] = ids;
    if (tags.length > 0) {
      data["tags"] = tags;
    }

    const stringifiedData = JSON.stringify(data);

    const response = await API.post(`/offers`, stringifiedData);
    if (response.ok) {
      toast?.createToast(ToastType.SUCCESS, "Salvestamine õnnestus");
    } else {
      toast?.createToast(ToastType.ERROR, "Salvestamine ebaõnnestus");
    }
  };

  return (
    <Main>
      <SplitOuterContainer>
        <HeaderContainer>
          <h1>Uus pakkumus</h1>
          <ButtonGroup>
            <Dialog>
              <DialogTrigger asChild>
                <Button>Salvesta mallina</Button>
              </DialogTrigger>
              <DialogContent>
                <Form ref={form} onSubmit={handleSubmit}>
                  <Fieldset>
                    <Label htmlFor="title">Malli pealkiri</Label>
                    <Input id="title" name="title" />
                  </Fieldset>
                  <Fieldset>
                    <Label htmlFor="tags">Tagid</Label>
                    <TagInput>
                      <TagUL id="tags">
                        {tags.map((tag, index) => (
                          <TagLI key={index}>
                            <span>{tag}</span>
                            <div onClick={() => removeTags(index)}>
                              <BsXCircle />
                            </div>
                          </TagLI>
                        ))}
                      </TagUL>
                      <input
                        type="text"
                        placeholder={'Vajuta "enter" või "," tagide lisamiseks'}
                        onKeyDown={(e) =>
                          e.key === "Enter" || e.key === "," ? addTags(e) : null
                        }></input>
                    </TagInput>
                  </Fieldset>
                  <ButtonGroup>
                    <DialogClose>Tühista</DialogClose>
                    <Button variant="primary">Salvesta</Button>
                  </ButtonGroup>
                </Form>
              </DialogContent>
            </Dialog>
            <Button onClick={exportToDocx}>Ekspordi</Button>
          </ButtonGroup>
        </HeaderContainer>
        <SplitContainer>
          <SplitWindow>
            <WideBlockContainer>
              {leftContentBlocks
                .filter(
                  (block) =>
                    !rightContentBlocks.map((b) => b._id).includes(block._id)
                )
                .map((blockObj) => (
                  <WideBlock
                    key={blockObj._id}
                    _id={blockObj._id}
                    title={blockObj.title}
                    lastModifiedAt={blockObj.lastModifiedAt}
                    onPlusClick={() => {
                      moveContentBlockToRight(blockObj);
                    }}
                  />
                ))}
            </WideBlockContainer>
          </SplitWindow>
          <SplitWindow>
            <WideBlockContainer>
              {rightContentBlocks.map((blockObj) => (
                <WideBlock
                  key={blockObj._id}
                  _id={blockObj._id}
                  title={blockObj.title}
                  lastModifiedAt={blockObj.lastModifiedAt}
                  onCrossClick={() => {
                    moveContentBlockToLeft(blockObj);
                  }}
                  onArrowClick={(direction) => {
                    moveInDirection(blockObj, direction);
                  }}
                />
              ))}
            </WideBlockContainer>
          </SplitWindow>
        </SplitContainer>
      </SplitOuterContainer>
    </Main>
  );
};

export { CreateOffer };
