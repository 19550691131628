import { createContext, useContext, useState } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { findCookie } from "../utils/API";

interface LoginAccessToken {
  assignAccessToken(newAccessToken: string): void;
  revokeAccessToken(): void;
  accessToken?: string;
}

const AuthContext = createContext<LoginAccessToken>(null!);

const useAuth = () => {
  return useContext(AuthContext);
};

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  const location = useLocation();
  if (!auth.accessToken) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }
  return children;
};

function AuthProvider({ children }: { children: React.ReactNode }) {
  let [accessToken, setAccessToken] = useState<string | undefined>(
    findCookie("access_token")
  );

  const assignAccessToken = (newAccessToken: string) => {
    setAccessToken(newAccessToken);
  };

  const revokeAccessToken = () => {
    // This is fine as long as we only have the 1 cookie in total
    document.cookie = ""
    setAccessToken(undefined);
  };

  const value: LoginAccessToken = {
    accessToken,
    assignAccessToken,
    revokeAccessToken,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export { AuthContext, useAuth, RequireAuth, AuthProvider };
export type { LoginAccessToken };
