import React from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { styled } from "../theme";

const Bar = styled("input", {
    fontSize: "1em",
    width:"100%",
    height:'100',
    border:"1px solid black",
  });

const Wrapper = styled("div", {
    border:'0px',
    display:"flex",
    verticalAlign: 'bottom',
    height:"2em"
});
  

type Props = {
  onChange: (str: string) => void;
  placeholder?: string;
  name: string;
  value?: string;
};

function SearchBar({ onChange, name, placeholder, value }: Props) {
    return (
    <Wrapper>
    <label htmlFor={name}>
        <AiOutlineSearch style={{height:'2em',width:'2em', margin:'auto'}} />
    </label>
    
    <Bar 
        onChange={event => onChange(event.target.value)}
        name={name}
        placeholder={placeholder}
        value={value}>
    </Bar>
    </Wrapper> );
}

export default SearchBar;