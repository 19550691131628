import * as ToastPrimitive from "@radix-ui/react-toast";
import {
  createContext,
  FC,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { styled } from "../theme";

interface ToastProps {
  children?: String;
  type?: ToastType;
}

interface ToastHandle {
  publish: () => void;
}

enum ToastType {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  WARNING = "WARNING",
  INFO = "INFO",
}

interface ToastContext {
  createToast(type: ToastType, description: String): void;
}

const ToastContext = createContext<ToastContext | null>(null);

/** The naive approach of letting Radix handle this won't work if you navigate
 * with React Router while a Toast is visible on the screen, so we make our own
 * ToastProvider that wraps Toast.Provider. */
const ToastProvider: FC<ToastPrimitive.ToastProviderProps> = ({
  children,
  ...props
}) => {
  const toastRef = useRef<ToastHandle>(null);
  const [toasts, setToasts] = useState<ToastProps[]>([]);
  return (
    <ToastContext.Provider
      value={{
        createToast: (type: ToastType, description: String) => {
          setToasts([
            ...toasts,
            {
              children: description,
              type,
            },
          ]);
          toastRef.current?.publish();
        },
      }}
    >
      <ToastPrimitive.Provider {...props}>
        {children}
        {toasts.map(({ children: description, type }, index) => (
          <Toast key={index} ref={toastRef} type={type}>
            {description}
          </Toast>
        ))}
      </ToastPrimitive.Provider>
    </ToastContext.Provider>
  );
};

const ToastClose = styled(ToastPrimitive.Close, {
  gridArea: "close",
  background: "unset",
  border: "1px solid rgba(0 0 0 / 0.25)",
  borderRadius: "$small",
  margin: "0",
  padding: "0",
  "&:hover": {
    backgroundColor: "rgba(0 0 0 / 0.1)"
  }
});

const ToastDescription = styled(ToastPrimitive.Description, {
  gridArea: "description",
});

const ToastViewport = styled(ToastPrimitive.Viewport, {
  position: "fixed",
  display: "flex",
  flexDirection: "column",
  right: "1%",
  bottom: "1%",
  zIndex: 10,
  listStyle: "none",
  paddingInlineStart: "1em",
});

const ToastRoot = styled(ToastPrimitive.Root, {
  display: "grid",
  gap: "2px",
  gridTemplateAreas: `
  "title title close"
  "description description close"
  `,
  gridTemplateColumns: "1fr 1fr 2em",
  minWidth: "35ch",
  minHeight: "4em",
  marginBlock: ".5em",
  padding: "1em",
  backgroundColor: "white",
  borderRadius: "$small",
  boxShadow: "$medium",
  variants: {
    type: {
      SUCCESS: {
        color: "$green900",
        backgroundColor: "$green100",
      },
      WARNING: {
        color: "$red900",
        backgroundColor: "$red100",
      },
      ERROR: {
        color: "$red900",
        backgroundColor: "$red100",
      },
      INFO: {
        color: "$blue900",
        backgroundColor: "$blue100",
      },
    },
  },
});

const Toast = forwardRef<ToastHandle, ToastProps>(
  ({ children, type }, forwardedRef) => {
    const [count, setCount] = useState(0);

    useImperativeHandle(forwardedRef, () => ({
      publish: () => setCount((count) => count + 1),
    }));

    return (
      <>
        {Array.from({ length: count }).map((_, index) => (
          <ToastRoot key={index} type={type}>
            <ToastDescription>{children}</ToastDescription>
            <ToastClose>×</ToastClose>
          </ToastRoot>
        ))}
      </>
    );
  }
);

export { Toast, ToastViewport, ToastProvider, ToastContext, ToastType };
export type { ToastHandle };
