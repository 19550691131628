import {
  BiCog,
  BiExpand,
  BiHistory,
  BiPlusCircle,
  BiXCircle,
  BiUpArrow,
  BiDownArrow,
} from "react-icons/bi";
import { styled } from "../theme";
import estonianDateFormatter from "../utils/DateFormatter";
import { Button } from "./Button";

const Container = styled("div", {
  position: "relative",
  border: "solid",
  borderWidth: "2px",
  padding: ".75rem",
  display: "grid",
  gap: "4px",
  minHeight: "8rem",
  gridTemplateColumns: "auto auto auto 2.25rem",
  gridTemplateRows: "auto 1fr auto",
  gridTemplateAreas: `
  "title title title plus"
  "preview preview preview options"
  "date date . ."
  `,
});

const ActionButton = styled(Button, {
  backgroundColor: "transparent",
  padding: "0",
  width: "100%",
  height: "auto",
  boxShadow: "0",
});

const DateElement = styled("div", {
  gridArea: "date",
  display: "flex",
  alignItems: "center",
  gap: "4px",
});

const Title = styled(ActionButton, {
  marginBlock: "0",
  justifyContent: "start",
  gridArea: "title",
  display: "flex",
  alignItems: "center",
  gap: "8px",
});

const Preview = styled("div", {
  gridArea: "preview",
});

const Plus = styled(ActionButton, {
  justifySelf: "end",
  gridArea: "plus",
  "& > svg": {
    color: "green",
  },
});

const Cross = styled(ActionButton, {
  justifySelf: "end",
  gridArea: "plus",
  "& > svg": {
    color: "red",
  },
});

const Options = styled(ActionButton, {
  justifySelf: "end",
  gridArea: "options",
});

const Arrow = styled(ActionButton, {
  "&:hover > svg": {
    color: "gray",
  },
});

// TODO: This should be visibly outside the container
const ArrowContainer = styled("div", {
  position: "absolute",
  right: "0.25rem",
  top: "90px",
  display: "flex",
  flexDirection: "column",
  height: "100px",
});

export enum Direction {
  UP = -1,
  DOWN = 1,
}

interface WideBlockProps {
  _id: string;
  title: string;
  lastModifiedAt: Date;
  onPlusClick?: () => void;
  onCrossClick?: () => void;
  onArrowClick?: (direction: Direction) => void;
}

const WideBlock = (props: WideBlockProps) => {
  const formattedDate = estonianDateFormatter.format(
    new Date(props.lastModifiedAt)
  );

  if (props.onPlusClick === undefined && props.onCrossClick === undefined) {
    throw Error("WideBlock must have a destructive action function");
  }
  if (props.onCrossClick !== undefined && props.onArrowClick === undefined) {
    throw Error("If .onCrossClick is defined, so must be .onArrowClick");
  }

  return (
    <div>
      <Container>
        <Title>
          <BiExpand />
          <b>{props.title}</b>
        </Title>
        <Preview>lorem ipsum</Preview>
        <DateElement>
          <BiHistory />
          {formattedDate}
        </DateElement>
        {props.onPlusClick && (
          <Plus
            onClick={() => {
              // We already know this is not undefined
              props.onPlusClick!();
            }}
          >
            <BiPlusCircle />
          </Plus>
        )}
        {props.onCrossClick && (
          <Cross
            onClick={() => {
              props.onCrossClick!();
            }}
          >
            <BiXCircle />
          </Cross>
        )}
        <Options>
          <BiCog />
        </Options>
        {props.onArrowClick && (
          <ArrowContainer>
            <Arrow
              onClick={() => {
                props.onArrowClick!(Direction.UP);
              }}
            >
              <BiUpArrow />
            </Arrow>
            <Arrow
              onClick={() => {
                props.onArrowClick!(Direction.DOWN);
              }}
            >
              <BiDownArrow />
            </Arrow>
          </ArrowContainer>
        )}
      </Container>
    </div>
  );
};

export { WideBlock };
