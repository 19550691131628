import { styled } from "../theme";

const Form = styled("form", {
  display: "flex",
  flexDirection: "column",
  gap: "1em",
});

const Fieldset = styled("fieldset", {
  padding: "0",
  margin: "0",
  border: "none",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  marginBottom: "1em",
});

const TagInput = styled("fieldset", {
  display: "flex",
  alignItems: "flex-start",
  flexWrap: "wrap",
  minHeight: "48px",
  padding: "0 8px",
  border: "1px solid #667b99",
  borderRadius: "6px",

  "& input": {
    flex: "1",
    border: "none",
    height: "46px",
    fontSize: "14px",
    padding: "4px 0 0 0 ",
    "&:focus": {
      outline: "transparent",
    },
  },
});

const TagUL = styled("ul", {
  display: "flex",
  flexWrap: "wrap",
  padding: "0",
  margin: "8px 0 0 0",
});

const TagLI = styled("li", {
  width: "auto",
  height: "32px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#fff",
  padding: "0 8px",
  fontSize: "14px",
  listStyle: "none",
  borderRadius: "6px",
  margin: "0 8px 8px 0",
  background: "#00E589",

  "& span": {
    marginTop: "3px",
  },

  "& div": {
    display: "block",
    width: "16px",
    height: "16px",
    marginLeft: "8px",
    cursor: "pointer",
  },
});

const Label = styled("label", {
  color: "black",
});

const Input = styled("input", {
  paddingInline: "1ch",
  border: "1px solid $gray300",
  borderRadius: "$small",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "1rem",
  lineHeight: 1,
  height: "40px",
});

export { Fieldset, Form, Input, Label, TagInput, TagUL, TagLI };
