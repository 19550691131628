export const transposeFormDataEntries = (
  arrayOfPairs: IterableIterator<[string, FormDataEntryValue]>
): {
  [key: string]: string;
} => {
  return [...arrayOfPairs].reduce(
    (prev, [key, value]) => ({ ...prev, [key]: value.toString() }),
    {} as { [key: string]: string }
  );
};
