import { styled } from "../theme";
import { Fieldset, Form, Input, Label } from "../components/FormComponents";
import { Button } from "../components/Button";

const OuterContainer = styled("main", {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: "100vw",
});

const TitleContainer = styled("div", {
  width: "1200px",
  height: "400x",
  fontSize: "25px",
  maxWidth: "100vw",
  color: "$dblue",
  paddingLeft: "5px",
});

const InnerContainer = styled("section", {
  width: "1200px",
  height: "400x",
  maxWidth: "100vw",
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "15px",
});

const NameDiv = styled("div", {
  gridArea: "div1",
  display: "flex",
  flexDirection: "column",
});

const PasswordDiv = styled("div", {
  gridArea: "div2",
  display: "flex",
  flexDirection: "column",
});

const FormContainer = styled(Form, {
  backgroundColor: "#F3F6FA",
  flexDirection: "row",
  padding: "20px",
  display: "grid",
  gridTemplateColumns: "auto",
  gridTemplateRows: "auto",
  gridTemplateAreas: `
  "div1 div2"
  `,
});

const Profile = () => {
  return (
    <OuterContainer>
      <TitleContainer>
        <h1>Minu konto</h1>
      </TitleContainer>
      <InnerContainer>
        <FormContainer>
          <NameDiv>
            <h2>Nime muutmine</h2>
            <Fieldset>
              <Label htmlFor="fullname">Nimi</Label>
              <Input id="fullname" name="fullname" />
            </Fieldset>

            <Fieldset>
              <Label htmlFor="email">Email</Label>
              <div id="email">kasutaja@twn.ee</div>
            </Fieldset>
          </NameDiv>

          <PasswordDiv>
            <h2>Salasõna muutmine</h2>
            <Fieldset>
              <Label htmlFor="password">Uus salasõna</Label>
              <Input id="password" name="password" />
            </Fieldset>
            <Fieldset>
              <Label htmlFor="passwordRepeat">Kinnita uus salasõna</Label>
              <Input id="passwordRepeat" name="passwordRepeat" />
            </Fieldset>
            <Button css={{ alignSelf: "end" }} variant="primary">
              Salvesta
            </Button>
          </PasswordDiv>
        </FormContainer>
      </InnerContainer>
    </OuterContainer>
  );
};

export { Profile };
