enum MethodType {
  GET = "get",
  POST = "post",
}

const findCookie = (name: string): string | undefined => {
  return document.cookie
    .split("; ")
    .find((entry) => entry.startsWith(`${name}=`))
    ?.split("=")[1];
};

const genericApiCall = (
  relativePath: string,
  body: BodyInit | null | undefined,
  methodType: MethodType
) => {
  if (!relativePath.startsWith("/")) {
    throw Error("relativePath must begin with `/`");
  }
  const options = {
    method: methodType,
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    } as {
      [key: string]: any;
    },
    body,
  };

  const token = findCookie("access_token");
  if (token !== undefined) {
    options.headers["Authorization"] = "Bearer " + token;
  }

  return fetch(`${process.env.REACT_APP_API_URL}${relativePath}`, options);
};

const API = {
  get(
    relativePath: string,
    body?: BodyInit | null | undefined
  ): Promise<Response> {
    return genericApiCall(relativePath, body, MethodType.GET);
  },
  post(
    relativePath: string,
    body: BodyInit | null | undefined
  ): Promise<Response> {
    return genericApiCall(relativePath, body, MethodType.POST);
  },
};

export default API;
export { findCookie };
