import logo from "../assets/img/twn-logo-white.svg";
import { styled } from "../theme";

const BaseFooter = styled("footer", {
  height: "10vh",
  backgroundColor: "$dblue",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});

const SvgImg = styled("img", {
  maxHeight: "50%",
});

const Footer = () => {
  return (
    <BaseFooter>
      <SvgImg src={logo} alt="logo" />
    </BaseFooter>
  );
};

export { Footer };
