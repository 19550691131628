import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../components/Auth";

const Logout = () => {
  const navigate = useNavigate();
  let auth = useAuth();
  useEffect(() => {
    auth.revokeAccessToken();
    navigate("/auth/login");
  });

  return <></>;
};

export { Logout };
