import { OutputData } from "@editorjs/editorjs";
import React, {
  FormEvent,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { createReactEditorJS } from "react-editor-js";
import { BsXCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/Button";
import { ContentEditorContainer } from "../components/ContentEditorContainer";
import {
  Fieldset,
  Form,
  Input,
  Label,
  TagInput,
  TagLI,
  TagUL,
} from "../components/FormComponents";
import { ToastContext, ToastType } from "../components/Toast";
import { EDITOR_JS_TOOLS } from "../tools";
import API from "../utils/API";
import { transposeFormDataEntries } from "../utils/transpose";
import { Main } from "../components/Main";

interface EditorCore {
  destroy(): Promise<void>;
  clear(): Promise<void>;
  save(): Promise<OutputData>;
  render(data: OutputData): Promise<void>;
}

enum ResultMessage {
  SUCCESS = "Salvestamine õnnestus",
  FAIL = "Salvestamine ebaõnnestus",
  NO_TITLE = "Palun lisage sisuplokile nimetus",
  NO_CONTENT = "Puudub sisu",
}

const CreateContentBlock = () => {
  const navigate = useNavigate();
  const EditorEstonian = createReactEditorJS();
  const EditorEnglish = createReactEditorJS();

  const [tags, setTags] = React.useState<string[]>([]);

  const addTags = (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.currentTarget.value) {
      setTags([...tags, e.currentTarget.value]);
      e.currentTarget.value = "";
    }
  };

  const removeTags = (indexToRemove: number) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };

  const form = useRef<HTMLFormElement | null>(null);
  const [editorCoreEstonian, setEditorCoreEstonian] =
    useState<EditorCore | null>(null);
  const [editorCoreEnglish, setEditorCoreEnglish] = useState<EditorCore | null>(
    null
  );

  const toast = useContext(ToastContext);

  const handleInitializeEstonian = useCallback((instance) => {
    setEditorCoreEstonian(instance);
  }, []);

  const handleInitializeEnglish = useCallback((instance) => {
    setEditorCoreEnglish(instance);
  }, []);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const formData = new FormData(form.current as HTMLFormElement);

    const data = transposeFormDataEntries(formData.entries()) as {
      [key: string]: string | string[] | OutputData;
    };

    const contentEstonian = await editorCoreEstonian?.save();
    const contentEnglish = await editorCoreEnglish?.save();

    data["contentEstonian"] = contentEstonian ?? "";
    data["contentEnglish"] = contentEnglish ?? "";
    if (tags.length > 0) {
      data["tags"] = tags;
    }
    const stringifiedData = JSON.stringify(data);

    if (String(data.title).length === 0) {
      toast?.createToast(ToastType.WARNING, ResultMessage.NO_TITLE);
      return;
    }

    if (
      Object.entries(data.contentEstonian)[1][1].length === 0 &&
      Object.entries(data.contentEnglish)[1][1].length === 0
    ) {
      toast?.createToast(ToastType.WARNING, ResultMessage.NO_CONTENT);
      return;
    }

    const response = await API.post(`/contentblocks`, stringifiedData);

    if (response.ok) {
      toast?.createToast(ToastType.SUCCESS, ResultMessage.SUCCESS);
      navigate("/");
    } else {
      toast?.createToast(ToastType.ERROR, ResultMessage.FAIL);
    }
  };

  return (
    <Main>
      <Form ref={form} onSubmit={handleSubmit}>
        <Fieldset>
          <Label htmlFor="title">Sisuploki nimi</Label>
          <Input id="title" name="title" />
        </Fieldset>
        <Fieldset>
          <Label htmlFor="contentEstonian">Sisu eesti keeles</Label>
          <EditorEstonian
            onInitialize={handleInitializeEstonian}
            holder={"contentEstonian"}
            tools={EDITOR_JS_TOOLS}
          >
            <ContentEditorContainer id="contentEstonian"></ContentEditorContainer>
          </EditorEstonian>
        </Fieldset>
        <Fieldset>
          <Label htmlFor="contentEnglish">Sisu inglise keeles</Label>
          <EditorEnglish
            onInitialize={handleInitializeEnglish}
            holder={"contentEnglish"}
            tools={EDITOR_JS_TOOLS}
          >
            <ContentEditorContainer id="contentEnglish"></ContentEditorContainer>
          </EditorEnglish>
        </Fieldset>
        <Fieldset>
          <Label htmlFor="tags">Tagid</Label>
          <TagInput>
            <TagUL id="tags">
              {tags.map((tag, index) => (
                <TagLI key={index}>
                  <span>{tag}</span>
                  <div onClick={() => removeTags(index)}>
                    <BsXCircle />
                  </div>
                </TagLI>
              ))}
            </TagUL>
            <input
              type="text"
              placeholder={'Vajuta "enter" või "," tagide lisamiseks'}
              onKeyDown={(e) =>
                e.key === "Enter" || e.key === "," ? addTags(e) : null
              }
            ></input>
          </TagInput>
        </Fieldset>
        <Button css={{ alignSelf: "end" }} variant="primary">
          Salvesta
        </Button>
      </Form>
    </Main>
  );
};

export { CreateContentBlock };
