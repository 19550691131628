import { BsClockHistory } from "react-icons/bs";
import { styled } from "../theme";
import estonianDateFormatter from "../utils/DateFormatter";

const Container = styled("div", {
  border: "solid",
  borderWidth: "2px",
  padding: ".5em",
  display: "grid",
  gridTemplateAreas: `
  "preview preview"
  "title title"
  "date options"
  `,
  minHeight: "100px",
  minWidth: "50px",
});

const DateElement = styled("div", {
  gridArea: "date",
  display: "flex",
  alignItems: "center",
  gap: "4px",
});

const Title = styled("div", {
  gridArea: "title",
});

const Preview = styled("div", {
  gridArea: "preview",
});

const RecentBlock = (props: any) => {
  const formattedDate = estonianDateFormatter.format(new Date(props.lastModified));

  return (
    <Container>
      <Preview>lorem ipsum</Preview>
      <Title>{props.title}</Title>
      <DateElement>
        <BsClockHistory />
        {formattedDate}
      </DateElement>
    </Container>
  );
};

export { RecentBlock };
