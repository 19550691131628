import { useEffect, useState } from "react";
import { Main } from "../components/Main";
import { RecentBlock } from "../components/RecentBlock";
import SearchBar from "../components/SearchBar";
import { styled } from "../theme";
import API from "../utils/API";

const BlocksDiv = styled("div", {
  display: "grid",
  gap: "8px",
  gridAutoFlow: "row",
  gridTemplateColumns: "repeat(4, 1fr)",
});

const Recents = () => {
  const [blocks, setBlocks] = useState([] as any[]);
  const [loading, setLoading] = useState(true);
  //const [searchResults, setSearchResults] = useState<any[]>([])

  const fetchData = async () => {
    // get the data from the api
    const response = await API.get("/contentblocks");
    if (response.ok) {
      // convert the data to json
      const json = await response.json();
      // set state with the result
      setBlocks(json);
      setLoading(false);
    } else {
      setBlocks([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData().catch(console.error);
  }, []);

  const [searchInput, setSearchInput] = useState("");
  const onChange = (str: string) => {
    setSearchInput(str);
  };

  function filterFunc(element: any) {
    var filterResults = [];
    if (searchInput.length > 2) {
      if (element.title.toLowerCase().includes(searchInput.toLowerCase())) {
        filterResults.push(
          <RecentBlock
            key={element._id}
            title={element.title}
            lastModified={element.lastModifiedAt}
          />
        );
      }

      element.contentEstonian.blocks.map((block: { data: string }) => {
        for (const iterator of Object.values(block.data)) {
          if (typeof iterator == "string") {
            if (iterator.toLowerCase().includes(searchInput.toLowerCase())) {
              filterResults.push(
                <RecentBlock
                  key={element._id}
                  title={element.title}
                  lastModified={element.lastModifiedAt}
                />
              );
            }
          }
        }
      });

      element.contentEnglish.blocks.forEach((block: { data: string }) => {
        for (const iterator of Object.values(block.data)) {
          if (iterator.toLowerCase().includes(searchInput.toLowerCase())) {
            filterResults.push(
              <RecentBlock
                key={element._id}
                title={element.title}
                lastModified={element.lastModifiedAt}
              />
            );
          }
        }
      });
      return filterResults;
    } else {
      return (
        <RecentBlock
          key={element._id}
          title={element.title}
          lastModified={element.lastModifiedAt}
        />
      );
    }
  }

  return (
    <Main>
      <SearchBar name="search" onChange={onChange} />
      <h1>Hiljutised</h1>
      {loading || !blocks ? (
        <div>Loading...</div>
      ) : (
        <BlocksDiv>{blocks.map((blockObj) => filterFunc(blockObj))}</BlocksDiv>
      )}
    </Main>
  );
};

export { Recents };
