import { Link } from "react-router-dom";
import { styled } from "../theme";
import UserDropdown from "./UserDropdown";

const BaseHeader = styled("header", {
  backgroundColor: "$dblue",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "3rem 2rem 3rem",
  color: "white",
  gap: "1rem",
});

const Header = () => {
  return (
    <BaseHeader>
      <IndexLink to="/">Pakkumuse generaator</IndexLink>
      <HeaderLink to="/contentblock/create">Uus sisuplokk</HeaderLink>
      <HeaderLink to="/offer/create">Uus pakkumus</HeaderLink>
      <HeaderLink to="/contentblock/all">Sisuplokid</HeaderLink>
      <HeaderLink to="/offer/all">Pakkumusepõhjad</HeaderLink>
      <UserDropdown />
    </BaseHeader>
  );
};

const IndexLink = styled(Link, {
  color: "white",
  fontWeight: "600",
  fontSize: "1.25em",
  textDecoration: "none",
  flex: 1,
});

const HeaderLink = styled(Link, {
  color: "white",
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
});

export { Header };
