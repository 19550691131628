import { styled } from "../theme";

export const Button = styled("button", {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  border: "none",
  borderRadius: "$small",
  padding: "1rem",
  fontSize: "1rem",
  lineHeight: 1,
  fontWeight: 600,
  height: "3rem",
  cursor: "pointer",

  variants: {
    variant: {
      green: {
        backgroundColor: "$green",
        color: "$dblue",
        boxShadow: "$button",
        "&:hover": { backgroundColor: "$bgreen" },
      },
      primary: {
        backgroundColor: "$dblue",
        color: "white",
        "&:hover": { opacity: 0.92 },
      },
    },
  },
});
