import React from "react";
import { styled } from "../theme";

const BaseContentEditorContainer = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithRef<"div">
>((props, ref) => {
  return <div {...props} ref={ref}></div>;
});

const ContentEditorContainer = styled(BaseContentEditorContainer, {
  border: "1px solid $gray300",
  borderRadius: "$small",
  "& .cdx-block": {
    border: "1px solid $gray300",
    borderRadius: "$small",
    marginBlock: "8px",
    paddingInline: "8px",
  },
  "& .cdx-block:hover": {
    backgroundColor: "#201e300f",
  },
  "& .codex-editor": {
    marginInline: "8ch",
  },
});

export { ContentEditorContainer };
