import { styled } from "../theme";

const Main = styled("main", {
  backgroundColor: "white",
  padding: "2em 8ch",
  width: "820px",
  maxWidth: "100vw",
  margin: "0 auto",
  boxShadow: "$medium",
  flex: 1
});

export { Main };
