import * as DialogPrimitive from "@radix-ui/react-dialog";
import { forwardRef, HTMLProps } from "react";
import { styled } from "../theme";
import { Button } from "./Button";

const DialogOverlay = styled(DialogPrimitive.Overlay, {
  background: "rgba(0 0 0 / 0.5)",
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "grid",
  placeItems: "center",
  overflowY: "auto",
});

export const DialogClose = styled(DialogPrimitive.Close, Button);

const Content = styled(DialogPrimitive.Content, {
  display: "flex",
  flexDirection: "column",
  width: "540px",
  maxWidth: "100vw",
  background: "white",
  padding: "2rem",
});

type DialogProps = HTMLProps<HTMLDivElement>;

export const DialogContent = forwardRef<HTMLDivElement, DialogProps>(
  ({ children, ...props }, forwardedRef) => (
    <DialogPrimitive.Portal>
      <DialogOverlay>
        <Content {...props} ref={forwardedRef}>
          {children}
        </Content>
      </DialogOverlay>
    </DialogPrimitive.Portal>
  )
);

export const Dialog = DialogPrimitive.Root;
export const DialogTrigger = DialogPrimitive.Trigger;
