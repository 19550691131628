const downloadBlob = (filename: string, data: Blob) => {
  const url = window.URL.createObjectURL(data);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();
  a.remove();
  setTimeout(() => window.URL.revokeObjectURL(url), 100);
};

export default downloadBlob;
